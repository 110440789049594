/* annoncecard.css */

.annonce-card{
  border-radius: 18px;
  overflow: hidden;
  margin: 16px;
  max-width: 370px;
  min-width: 370px;
  max-height: 630px;
  min-height: 630px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.215);
}

.annonce-card-vendu{
  border-radius: 18px;
  overflow: hidden;
  margin: 16px;
  max-width: 370px;
  min-width: 370px;
  max-height: 500px;
  min-height: 500px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.215);
}

.photo-slider {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}

.photo-slider img {
  flex: 0 0 auto;
  width: auto;
  scroll-snap-align: start;
  cursor: pointer;
  border-radius: 8px 8px 0 0;
  object-fit: cover;
  
}

.vehicle-info {
  padding: 16px;
  background-color: #fff;
  min-height: 235px;
}

.vehicle-title {
  color: #000;
  text-align: left;
  margin-bottom: 5px;
  margin-top: 0px;
}

.vehicle-detailsPrecis {
  color: #656565;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 600;
}

.vehicle-details {
  color: #000;
  text-align: left;
  font-weight: 400;
  margin-top: 8px;
  
}

.vehicle-price {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  margin-top: 8px;
  color: #000;
  margin-bottom: 8px;
}

.btn-more {
  background-color: #3498db;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 12px;
}

/* AnnonceCard.css */

.slider-image {
  width: 100%;
  height: 230px;
  object-fit: cover;
}

.slider-image img {
  flex: 0 0 auto; 
  scroll-snap-align: start;
  cursor: pointer;
  border-radius: 8px 8px 0 0;
}

.bar-separation {
  width: 100%;
  height: 0px;
  background-color: #c9c7c7;
  border: 0.5px solid #c9c7c7;
  border-radius: 10px;
}

.vehicle-localisation {
  text-align: left;
  color: #000;
  margin-top: auto;
  
}

/* ... (Autres règles CSS) */

.affaire-indicateur {
  display: flex;
  flex-direction: row;
  border: 1px solid #333;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  padding: 0px 0px;
  margin: 0px;
  font-weight: 500;
}

.paffaire-indicateur {
  margin: 3px;
  padding-left: 2px;
}

.tres-bonne-affaire {
  border-color: #00ff00; /* Vert fluo */
  color: #00ff00;
  background-color: #00ff001d;
  width: 165px;
}

.bonne-affaire {
  border-color: #008000; /* Vert foncé */
  color: #008000;
  background-color: #0080001d;
  width: 132px;
}

.offre-equitable {
  border-color: #6CA59A; 
  color: #6CA59A;
  background-color: #6CA59A1d;
  width: 145px;
}

.au-dessus-du-marche {
  border-color: #FC8C00; /* Rouge */
  color: #FC8C00;
  background-color: #FC8C001d;
  width: 188px;
  
}

.analyse-indisponible {
  border-color: #666; 
  color: #666;
  width: 180px;
}

.imgaffaire {
  width: 14px;
  height: 14px;
  margin: 6px;
  margin-top: 6px;
  margin-right: 0px;
  padding: 0px;
 }

 .spaced {
  letter-spacing: 1px; /* Ajustez la valeur selon vos préférences */
}

.NoVehicleFound {
  color: white;
  text-align: center;
  margin-top: 200px;
  margin-bottom: 200px;
  font-size: 30px;
  font-weight: bold;
}

@media screen  and (max-width: 768px) {
  .NoVehicleFound {
    margin-top: 100px;
    margin-bottom: 100px;
    font-size: 20px;
  }
}

.imglivraison {
  width: 20px;
  height: 20px;
  margin: 6px;
  margin-top: 3px;
  margin-right: 0px;
  padding: 0px;
 }

 .plivraison {
  margin: 3px;
  padding: 0px;
 }

 .livraison {
  color: #000;
  display: flex;
  flex-direction: row;
  border: 1px solid #D9D9D9;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  padding: 1px;
  font-weight: 500;
  width: 100px;
  margin-right: 10px;

}

 .imggarantie {
  width: 20px;
  height: 20px;
  margin: 6px;
  margin-top: 6px;
  margin-right: 0px;
  padding: 0px;
 }


.blank {
  width: 30px;
  height: 30px;
 }

 .garantie {
  color: #000;
  display: flex;
  flex-direction: row;
  border: 1px solid #D9D9D9;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  padding: 1px;
  font-weight: 500;
  width: 160px;
  margin: 0px;
  margin-right: 10px;
}

.pgarantie {
  margin: 3px;
  padding-left: 2px;
}

.imggarantie {
  width: 15px;
  height: 15px;
  margin: 6px;
  margin-top: 6px;
  margin-right: 0px;
  padding: 0px;
 }

.livraisonGarantie {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  height: 30px;
}

.ColContacter {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 0px;
  margin-left: 150px;
  margin-right:  150px;
  margin-bottom: 50px;

}

.ColContacterDesc {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.S3 {
  margin-left: 10px;
  margin-right: 10px;
  width: 70%;
  height: 70%;
}

.titreContacter {
  font-size: 60px;
}

@media screen and (max-width: 1000px) {
  .ColContacter {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 0px;
    margin-left: 0px;
    margin-right:  0px;
    margin-bottom: 50px;
  }
  .ColContacterDesc {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 50px;
  }

  .titreContacter {
    font-size: 60px;
  }

  .S3 {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    height: 100%;
  }

}

/* Votre fichier CSS actuel */
/* Ajoutez ces styles à votre feuille de style existante */
/* CSS pour la section de contact */

/* CSS pour la section de contact */

.ColContacter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  align-items: center;
}

/* Style pour le formulaire de contact */
.ColContacterForm {
  align-items: center;
  display: flex;
  flex-direction: column; 
}

.formContacter {
  display: flex;
  flex-direction: column;
}

.inputContacter,
.textareaContacter,
.boutonContacter {
  margin-bottom: 10px;
}

/* Style pour les coordonnées */


.Coordonnees p {  
  margin-bottom: 10px;
}

.OptionContact {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.IconContact {
  margin: 20px;
}

.IconContact img {
  width: 40px;
  height: 40px;
}

/* Style pour les liens de contact */
.Coordonnees a {
  color: #3498db;
  text-decoration: none;
}

.Coordonnees a:hover {
  text-decoration: underline;
}

/* Media Query pour la mise en page sur mobile */
@media (max-width: 768px) {
  .ColContacter {
    flex-direction: column; /* Une colonne sur mobile */
  }

  .ColContacterForm,
  .Coordonnees {
    width: 100%; /* Pleine largeur sur mobile */
    padding-right: 0;
  }

  .Coordonnees {
    margin-top: 20px; /* Ajout d'une marge entre les deux colonnes sur mobile */
  }
}

.ColContacterData {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 0px;
  margin-left: 150px;
  margin-right:  150px;
  margin-bottom: 50px;
}

.SMS,
.Mail,
.Téléphone,
.InstagramContact {
  margin-top: 40px;
}