.vehicle-list {
  color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: left;
  align-items: left;   
  margin-top: 20px;
  padding: auto;
  /*max-width: 1600px;*/
}

.vehicle-list-container {
  display: flex;
  justify-content: center;
  align-items: left;
  margin-bottom: 100px;
  margin-left: 5%;
  margin-right: 5%;
}       

@media screen and (max-width: 768px) {
  .vehicle-list {
    margin-bottom: 50px;
  }
}

.titleVDispo {
  color: white;
  font-size: 60px;
  text-align: left;
}

.vehicule-div {
  margin-left: 19%;
  margin-right: 5%;
}

@media screen and (max-width: 768px) {
  .vehicle-list {
    flex-direction: column;
    
  }
  .vehicle-list-container {
    align-items: center !important;
    justify-content: center !important;
  }
}
.slick-list {
  margin: -4px;
}

/*# filter */

/* Ajoutez vos styles ici */
.filters {
  display: flex;
  justify-content: space-around;
  margin-top: 0px;
  margin-bottom: 40px;
  margin-left: 30%;
  margin-right: 30%;
  color: white;
}

.filters label {
  margin-right: 20px;
}

.filters select,
.filters input {
  margin-top: 5px;
}

/* NosVehiculesContent.css */

/* Style pour la zone de filtre */
.filter-container {
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: #f9f9f9;
}

/* Style pour les boîtes de filtre */
.filter-box {
  margin-bottom: 12px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

/* Style pour les titres des boîtes de filtre */
.filter-box h3 {
  margin-bottom: 8px;
  color: #333;
}

/* Style pour les sélecteurs dans les boîtes de filtre */
.filter-box select {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Style pour les boutons "Appliquer" et "Réinitialiser" */
.filter-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.filter-actions button {
  background-color: #3498db;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.filter-actions button.reset-btn {
  background-color: #e74c3c;
}

/* Ajoutez d'autres styles au besoin */

.select {
  width: auto;
  padding: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.ColTitleVDispo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  margin: 10px;
  margin-left: 7%;
  margin-right: 12%;
  
}

.Megane4Title {
  margin-left: -5%;
  margin-right: -5%;
  width: 30%;
  height: 30%;
}

@media screen and (max-width: 1000px) {
  .ColTitleVDispo {
    display: block;
    margin-left: 70px;
    margin-bottom: 50px;
  }
  .Megane4Title {
    display: none;
  }
  .titleVDispo {
    font-size: 45px;
    margin: 0px;
    text-align: left;
  }
}