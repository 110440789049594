@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000000;
  padding: 10px 20px;
  color: white;
}

.Menu img,
.Contact img {
  max-width: 40px;
  height: auto;
  cursor: pointer;
}

.Logo img {
  max-height: 50px;
}

.LogoAmex {
  max-height: 35px;
}

.Contact img {
  width: 30px;
  height: auto;
  max-width: 50px;
}

.MenuOverlay {
  position: fixed;
  top: 65px;
  left: 0;
  height: 0%;
  width: 100%;
  overflow: hidden;
  z-index: 10;
  background-color: #000000f3;
  color: white;
  transition: height 0.4s ease;
}

.MobileMenu {
  list-style: none;
  padding: 10px;
  padding-left: 0%;
  margin-right: 20px;
  text-align: left;
  padding-top: 50px;
}

.MobileMenu li {
  margin: 50px;
  list-style-type: none;
}

.MobileMenu a {
  font-size: 60px; /* Police de grande taille */
  font-weight: bold; /* Police en gras */
  color: white;
  text-decoration: none; 
}

@media screen and (max-width: 1400px) {
  .MobileMenu a {
    font-size: 50px;
  }
  .MobileMenu li {
    margin: 30px;
  }
}

.MobileMenu a:hover {
  text-decoration: underline;
}

.MenuOverlay.open {
  height: 100%;
}

@media screen and (max-width: 600px) {
  .MobileMenu a {
    font-size: 25px;
  }
  .MobileMenu li  {
    margin-left: 0px;
  }
  .MenuOverlay {
    background-color: #000000;
  }
}

.App {
  text-align: center;
  background-color: #000000;
  height: 100%;
  font-family: 'Outfit', sans-serif;
  overflow: hidden;
}


.Footer {
  background-color: #000000;
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Outfit', sans-serif;
}

.ColFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .ColFooter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
  }
}

.LogoFooter {
  max-height: 50px;
}

/* Path: src/App.css */


.FooterSocial {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 20px;
  margin-top: 5px
}