.Coordonnees {
  font-size: 20px;
  font-weight: 600;
  color:white;
}
.Reprise     {
  color:white;
}

.titleVDispo {
  color:white;
}

.OptionContact {
  margin-top: -30px;
}

.titreContacter {
  color:white;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 60px;
  min-width: 600px;
}

@media screen and (max-width: 1000px) {
  .Coordonnees {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: left;
    margin: auto;
  }

  .ColContacterData {
    display: flex;
    width: 320px;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-bottom: 50px;
  }

  .ColContacter {
    margin-bottom: 0px !important;
  }

  .ColContacterDesc {
    margin-bottom: 0px;
  }

  .vehiculeDispo {
    margin-bottom: 0px;
    margin-top: 60px;
    padding: 0px;
  }

  .titreContacter {
    font-size: 40px;
    min-width: none;
  }
}

.IconContactez {
  margin: 20px;
  margin-top: 0px;
}

.IconContactez img {
  width: 20px;
  height: 20px;
}