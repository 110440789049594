/* VotreFormulaire.css */

.formulaire-container {
  max-width: 550px;
  margin: auto;
  color: white;
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 100px;
}

.form-title {
  text-align: center;
}

.form-step {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  text-align: center;
  margin-top: -10px;
}

label {
  margin-bottom: 10px;
  padding-right: 10px;
}

select,
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="number"],
textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border-radius: 7px;
}

button {
  background-color: #ffffff;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 18px;
  font-family: "Outfit", sans-serif;
  border: 1px solid #ffffff;
  font-weight: 500;
}

button:hover {
  background-color: #000000;
  color: white;
  border: 1px solid #ffffff;
  cursor: pointer;

}

button[type="submit"] {
  background-color: #008cba;
}

button[type="submit"]:hover {
  background-color: #007b95;
}


.labelmenuderoulant {
  text-align: left;
  align-items: left;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.label {
  width: 132%;
}

@media  screen and (max-width: 1000px) {

  .labelmenuderoulant {
    text-align: left;
    align-items: left;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }
}

.form-title {
  color: #ffffff;
}

.selectpage {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}


.bannieredubas {
  width: 100%;
  position: relative;
}

.bannieredubas::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 110px; /* Hauteur du dégradé */
  background: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
}

@media screen and (max-width: 1000px) {
  .bannieredubas::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 45px; /* Hauteur du dégradé */
    background: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
  }
  
}

.label {
  margin-top: 7px;
}


.checkbox {
  margin-top: 10px;
  margin-bottom: 10px;
}

.depotex {
  max-width: 550px;
}

.depotexcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

@media screen and (max-width: 1000px) {
  .depotex {
    max-width: 100%;
    margin-top: 0px;
  }
  
}
