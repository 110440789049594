.bouton {
    background-color: #000000;
    color: rgb(255, 255, 255);
    border: 1px solid #ffffff;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px; 
    font-size: 16px;
    font-weight: 400;
    font-family: "Outfit", sans-serif;
    margin: 30px;
  }
  
  .bouton:hover {
    background-color: #ffffffe4;
    color: #000000;
    border: 1px solid #000000;
    cursor: pointer;
  }

  .Modal {
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    
  }

.DivSlider2 {
    width: 800px;
    margin-right: 50px;
}

.slider-image2 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.slick-next {
    right: -35px;
}

.slick-prev {
    left: -35px;
}

.slick-next:before, .slick-prev:before {
    color: #ffffff;
}

@media  screen and (max-width: 1100px) {
    .DivSlider2 {
        width: 100%;
    }
    .Modal {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: 0px;
        margin-top: 10px;
    }

    .slick-next {
        right: 0px;
    }

    .ModalSup {
        margin-top: 10px;
    }
    
    .slick-prev {
        left: 0px;
        z-index: 1;
    }
    
}

.textContact {
    color: black;
    margin-bottom : 0px;
    margin-top: 0px;
    font-size: 16px;
    font-weight: 400;
}

.ColContacterData2 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 8px  !important;
    margin-top: 20px;
}


.Coordonnees2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 30px;
    margin-bottom: 0px;
}

.OptionContact2 {
    margin-bottom: 0px !important;
}

.ModalSup {
    overflow-x: hidden;

}

.loc2 {
    margin-bottom: 0px !important;
}