@font-face {
  font-family: 'Horizon';
  src: url('../public/horizon.otf') format('opentype');
}
.accueil {
  background-color: black;
  color: white;
}

.titrefor {
  font-size: 35px;
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 0px;
  padding-top: 20px;
  padding-bottom: 0px;
}

.ou {
  color: #CFC3B7;
  padding: 0px important;
  margin-top: 0px;
  margin-bottom: 0px;
}

.titreforMobile {
  font-size: 3Opx;
  font-family: 'Horizon', sans-serif;
}

@media screen and (max-width: 1750px) {
  .titreforMobile {
    font-size: 26px;
  }
}

@media screen and (max-width: 1400px) {
  .titreforMobile {
    font-size: 24px;
  }
}

.SliderBanniere {
  position: relative;
}

.SliderBanniere::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 250px; /* Hauteur du dégradé */
  background: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
}

.banniere {
  background-color: #363636;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
  padding: 10px;
  padding-top: 5px;
  margin: 0%;
  font-size: 20px;
}

.banniereMobile,
.banniereMobile2 {
  background-color: #363636;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
  margin: 0%;
  margin-bottom: 5px; 
  font-size: 15px;
}

.banniereMobile2 {
  padding-top: 0px;
  display: none;
}

.banniereMobile {
  padding-bottom: 0px;
  display: none;
}

@media screen and (max-width: 900px) {
  .banniere {
    font-size: 16.5px;
  }

  .titreforMobile {
    font-size: 16px;
  }
}

@media screen and (max-width: 700px) {
  .banniere {
    display: none;
  }

  .banniereMobile,
  .banniereMobile2 {
    display: block;
  }
  .banniereMobile2 {
    padding-top: 0px;
    padding-bottom: 10px;
  }
  .titreforMobile {
    font-size: 18px;
  }

  .titreforMobile {
    font-size: 16px;
  }
}

.M4 {
  width: 100%;
  height: 100%;
  z-index: -100;
}

.formules {
  display: flex;
  margin-right: 10px;
  margin-left: 5%;
  margin-right: 5%;
  justify-content: space-around; /* Ajustez selon vos préférences de placement */
  margin-top: -180px !important;
  position: relative;
  z-index: 1;
}

.SliderBanniere {
  z-index: 1;
}

.slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1000px) {
  .formules {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: -90px !important;
    justify-content: space-around;
    /* Ajustez selon vos préférences de placement */
  }
  .reprise,
  .dispo,
  .recherche {
    display: none;
  }
  .bouton {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .SliderBanniere::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25px; /* Hauteur du dégradé */
    background: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
  }
}

.rechercheMobile,
.dispoMobile,
.repriseMobile {
  display: none;
}

@media screen and (max-width: 1000px) {
  .rechercheMobile,
  .dispoMobile,
  .repriseMobile {
    border: 2px solid #ffffff; /* Ajoutez une bordure pour mieux visualiser les colonnes */
    box-sizing: border-box;
    display: block;
    background-color: #000000;
    margin-bottom: 20px;
    border: 2px solid #ffffff;
    border-radius: 10px;
    z-index: -1;
  }
  .logoMobile {
    height: 70px;
    margin: auto;
    z-index: -1;
    
  }
  .detail {
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  .logoMobile {
    height: 60px;
    margin: auto;
  }
}

.reprise,
.dispo,
.recherche {
  width: 29%; /* Ajustez la largeur des colonnes selon vos préférences */
  padding: 10px; /* Ajoutez de l'espace entre les éléments si nécessaire */
  border: 2px solid #ffffff; /* Ajoutez une bordure pour mieux visualiser les colonnes */
  box-sizing: border-box; /* Garantit que la bordure ne modifie pas la largeur totale */
  border-radius: 5px;
  background-color: #000000;
  padding-bottom: 0px;
  transition: transform 0.4s;
}

.repriseMobileTitre {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  text-align: left !important;
  margin-left: 20px;
  margin-right: 20px;
}

.repriseMobile:hover,
.dispoMobile:hover,
.rechercheMobile:hover {
  cursor: pointer;
}

.reprise:hover,
.dispo:hover,
.recherche:hover {
  transform: scale(1.05);
  cursor: pointer;
}
.logo {
  width: 80%;
}

.detail {
  background-color: rgb(45, 45, 45);
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  z-index: -1;
  margin-top: -4px;
  font-weight: 400;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0px;
}

.complet {
  display: flex;
  flex-direction: row;
  margin-left: 7%;
  margin-top: 2%;
  font-size: 30px;
}

@media screen and (max-width: 1000px) {
  .complet {
    display: flex;
    flex-direction: column;
    margin: 2%;
    font-size: 30px;
  }
}

.logoComplet {
  height: 70px;
  margin-top: 6px;
}

.Megane4 {
  width: 80%;
  height: 80%;
  z-index: -100;
}

.ColFooterEngagement {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  margin-left: 60px;
  margin-bottom: 50px;
}

.ColFooterEngagementDesc {
  max-width: 39%;
  margin: 10px;
  margin-left: 40px;
  margin-bottom: 50px;
  text-align: justify;
  color: #ebe9e9;
}


@media screen and (max-width: 1100px) {
  .ColFooterEngagement {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .ColFooterEngagementDesc {
    max-width: 100%;
    margin: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 50px;
    margin-top: 10px;
    text-align: justify;
  }

  .Megane4 {
    margin-left: -5%;
    margin-right: -5%;
    width: 110%;
    height: 110%;
    z-index: -100;
  }

  .titreforComplet {
    font-size: 135%;
  }

  .logoComplet {
    height: 50px;
    margin-top: -10px;
    margin-bottom: 20px;
  }
  .ou {
    margin-top: -3%;
    margin-bottom: -3%;
  }
  .completeDivTitre {
    font-size: 170%;
  }
}



.titreforComplet {
  margin-bottom: 10px;
  margin-top: 0px;
}

.bouton {
  background-color: #000000;
  color: rgb(255, 255, 255);
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
  margin: 30px;
}

.bouton:hover {
  background-color: #ffffffe4;
  color: #000000;
  border: 1px solid #000000;
  cursor: pointer;
}

.completeDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  color: rgb(255, 255, 255);
  border: 1px solid #ffffff;
  margin-left: 7%;
  margin-right: 7%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.completeDivTitre {
  min-width: 400px;
  align-items: center;
  font-family: Horizon;
  font-size: 30px;
}

.ou {
  font-size: 24px;
}

.formz {
  min-width: 400px;
  align-items: center;
}

@media screen and (max-width: 1000px) {
  .completeDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;
  }
}

.pointfor {
  font-size: 20px;
  margin-left: 6%;
  margin-right: 6%;
  margin-top: 0px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
}

.point {
  border-radius: 5px;
  background-color: #363636b6;
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 15px;
  min-width: 28.5%;
  max-width: 28.5%;
}

.detailpoint {
  text-align: left;
  font-size: 30px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.pointforDesc {
  color: #ffffffb2;
  font-size: 20px;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
}

@media screen and (max-width: 1000px) {
  .pointfor {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .pointforDesc {
    display: flex;
    flex-direction: column;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .point {
    max-width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .formules {
    margin-top: -48px !important;
  }
  .bouton {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
  }
}

.smaller-text {
  font-size: 0.9em; /* Ajustez cette valeur selon vos besoins */
}

@media (min-width: 1001px) {
  .smaller-text {
    font-size: 1em; /* Ajustez cette valeur pour les résolutions supérieures à 1000px */
  }
}

@media screen and (max-width: 1400px) {
  .completeDivTitre {
    font-size: 22px;
  }
  .ou {
    font-size: 18px;
  }
}

@media screen and (max-width: 1500px) {
  .completeDivTitre {
    font-size: 24px;
  }
  .ou {
    font-size: 18px;
  }
}
